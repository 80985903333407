.calendar__top{
  padding: 0.6rem;
  border-radius: 0.3rem;
  background-color: #d4d4d4;
  color: black;
  display: flex;
  justify-content: space-between;
}

.calendar__top__left{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.calendar__top__right{
  display: flex;
  gap: 1.5rem;
}


/*Navigacija kalendara*/
.calendar__navigation{
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.calendar__container{
  padding: 1rem;
  background-color: var(--color-ligth);
  border-radius: 0.5rem;
}

.calendar__navigation__left{
  display: flex;
  gap: 1rem;
}

.calendar__body{
  margin-top: 1rem;
  border-radius: 0.4rem;
  background-color: #d4d4d4;
}

.my__calendar__navigation{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.my__calendar__select {
  background-color: #d4d4d4;
}
.tooth-surface-rect-unselected {
  fill: #000000;
  stroke: black;
  stroke-width: 1;
  opacity: 0.1;
}

.tooth-surface-rect-selected {
  fill: #ff1b1b;
  stroke: black;
  stroke-width: 1;
  opacity: 0.6;
}


g:hover{
  cursor: pointer;
}
.all__doctors__table__top{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-green);
  border-radius: 0.3rem;
  padding: 0.5rem;
}

.all__doctors__table__top > h2 {
  color: var(--color-ligth);
}

.all__doctors__table__body__top{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr;
  background-color: var(--bg-green);
  border-radius: 0.3rem;
  padding: 0.5rem;
}

.all__doctors__table__body__top.data{
  margin: 0;
  background-color: #d4d4d4;
  color: black;
  border-radius: 0;
}

.doctor__add__container{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.doctor__form__top{
  background-color: var(--bg-green);
  padding: 0.5rem;
  border-radius: 0.3rem 0.3rem 0 0;
}

.doctor__form__top > h3 {
  color: var(--color-ligth);
}

.doctor__form__body{
  display: flex;
  flex-direction: column;
  background-color: #d4d4d4;
  color: var(--black);
  gap: 0.5rem;
  padding: 0.5rem;
}

.radioButton__grid__twoCollumns.doctor{
  grid-template-columns: 1fr 1fr 1fr;
}


.odontogramDetails__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  color: black;
}

.no__selection__div {
  background-color: #d4d4d4;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  border-radius: 0.3rem;
  padding: 1rem;
}

.odontogramDetails__left {
  background-color: #d4d4d4;
  border-radius: 0.4rem;
  padding: 0.6rem;
  display: grid;
}

.left__upper__left {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.left__upper__left__left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


.radioButton__flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
}

.radioButton__flex>img {
  width: 32px;
}

.radioButtons__div {
  margin-bottom: 1rem;
}

.radioButton__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.length__div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.odontogramFormFooter {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.diagnose__div {
  display: grid;
  grid-template-columns: 3.8fr 2fr;
  margin-top: 2rem;
  gap: 2rem;
}

.diagnose__div__left {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem 1rem;
}

.diagnose__div__right {
  display: flex;
  flex-direction: column;
}

.odontogramDetails__rigth {
  padding: 0.4rem 0.4rem;
  background-color: #d4d4d4;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.toothInfo__top {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.table__data {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.table__first__row {
  display: grid;
  color: black;
  grid-template-columns: 2fr 3fr 1.1fr 1fr;
  background-color: white;
  border-radius: 0.3rem;
}


.table__tooth__history__top.data {
  font-weight: 500;
  font-size: 12px;
}

.table__planed__interventions__top {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 1fr;
  background-color: #d4d4d4;
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-weight: 700;
}

.table__rtg__top {
  display: grid;
  grid-template-columns: 1.5fr 2fr 2fr 1.5fr 1fr 1fr;
  background-color: #d4d4d4;
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-weight: 700;
}

.treatments__pick__flex {
  display: flex;
  gap: 2rem;
  background-color: var(--color-ligth);
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.interventionsFilterFlex {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-ligth);
  padding: 0.3rem;
  border-radius: 0.3rem;
  gap: 2rem;
}

.interventionForm {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.textInputPart__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.textInputPart__flex {
  display: flex;
  align-items: top;
  gap: 1rem;
}

.interventionFormRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.length__input__div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.rootCanalCheckContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.canalCheckDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}
.patients__table__top{
  background-color: #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patients__table__navigation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
}

.patients__table__navigation__page{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.patients__table__navigation__search {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.patients__table__navigation__search > svg {
  font-size: 24px;
}

.patients__table__body{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  color: black;
}

.patients__table__body__top{
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1.5fr 1fr 2fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0.2rem 1rem;
  border-radius: 0.3rem;
  font-weight: 600;
}

.patients__table__body__top.data{
  background-color: #c4c4c4;
  font-weight: 500;
}
.statistic__card__container{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.statistic__title__top{
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.statistic__title__top.mid__charts{
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.statistic__top__container{
  display: grid;
  grid-template-columns: 1.3fr 3fr 3fr;
  gap: 1rem;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  padding: 1rem;
  color: black;
}

.patient__number__left, .birth_demography_chart, .age_demography_chart, .intervention__number__left, .top10__interventions__chart{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  background-color: #fff;
  border-radius: 0.3rem;
}

.patient__number__left, .intervention__number__left{
  max-height: 50px;
}

.statistic__bottom__container{
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  background-color: #d4d4d4;
  padding: 1rem;
  border-radius: 0.3rem;
  color: black;
}

.patients__card__top__container{
  display: flex;
  flex-direction: column;
  color: black;
}

.patients__card__top__container__top, .patients__card__top__container__top__left, .patients__card__top__container__top__right, .month__year__select{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.patients__card__top__container__top{
  justify-content: space-between;
  background-color: #d4d4d4;
  color: black;
  padding: 1rem;
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}

.patients__card__top__container__top__right{
  gap: 4rem;
}

.paients__card__top__container__bottom{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  background-color: #d4d4d4;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  padding: 1rem;
}

.patient__number__card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: white;
}

.patients__card__mid__container{
  display: flex;
  flex-direction: column;
  background-color: #d4d4d4;
  color: black;
  border-radius: 0.3rem;
  padding: 1rem;
  gap: 2rem;
}

.mid__container__top{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}


.new__patients__chart__container, .treated__patients__chart__container, .mid__container__bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.3rem;
  padding: 0.3rem;
}

.interventions__card__top__container__bottom{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  background-color: #d4d4d4;
  padding: 1rem;
  border-radius: 0 0 0.3rem 0.3rem;
}

.number__of__patients{
  background-color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
}
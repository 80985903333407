.tooth__info__container__top{
    background: #d4d4d4;
    padding: 0.5rem;
    border-radius: 0.3rem 0.3rem 0 0;
    color: black;
}

.tooth__info__container__body{
    background: var(--color-ligth);
    color: var(--black);
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    font-weight: 600;
    gap: 1rem;
    font-size: 16px;
}

.red__span{
    color: var(--red-color);
}
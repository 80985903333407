@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600&display=swap');

*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
}


:root{
    --color-ligth : #ffffff;
    --bg-color: #505050;
    --bg-green: #00596d;
    --bg-green-dark: #003946;
    --accent-color: #ffffff;
    --yellow-color: #fff128;
    --red-color: #ff2424;
    --black: #000;

    --container-width-lg: 80%;
    --container-width-md: 85%;

    --transition: all 500ms ease;
}



/*==========GENERAL STYLES========*/
::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

body{
    font-family: Montserrat, Poppins, Arial, Helvetica, sans-serif;
    color: var(--color-ligth);
    line-height: 1.7;
    overflow-x: hidden;
    background: var(--bg-green-dark);
}


.container {
    width: var(--container-width-md);
    max-width: 1920px;
    margin-inline: auto;
}

section {
    margin-top: 8rem;
}


h1,h2,h3,h4,h5{
    line-height: 1.2;
    color: var(--bg-green-dark);
}

h1{
    font-size: 3rem;
}

h2{
    font-size: 2rem;
}

a {
    color: var(--color-gray-100);
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}
hr{
    background: var(--bg-green-dark);
    height: 1px;
    width: 100%;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
}

p{
    word-wrap: break-word;  
}

input, select, textarea{
    border-radius: 0.2rem;
    padding: 0.3rem;
}

input[type="radio"] {
    cursor: pointer;
}

select{
    cursor: pointer;
}


label{
    font-weight: 500;
}


.btn {
    border: 2px solid var(--bg-green);
    color: var(--color-gray-100);
    width: fit-content;
    padding: 0.9rem 2rem;
    background-color: var(--bg-green);
    border-radius: 0.5rem;
    transition: var(--transition);
}

.btn.patient{
    background-color: var(--bg-green-dark);
    display: flex;
    gap: 0.2rem;
}

.btn:hover{
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    cursor: pointer;
}

.btn.lg{
    padding: 1rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
} 

.btn.sm{
    border: none;
    background: var(--accent-color);
    color: var(--bg-green);
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 500;
}

.btn.sm.variant{
    background: var(--bg-green);
    color: var(--color-ligth);
}

.btn.sm.variant:hover{
    background: var(--bg-color);
    color: var(--color-ligth);
}

.btn.sm:hover{
    background: var(--bg-color);
    color: var(--accent-color)
}

.btn.print{
    border: none;
    background-color: var(--bg-green);
    color: var(--color-ligth);
    padding: 0.2rem 0.6rem;
    font-size: 0.7rem;
    font-weight: 500;
}

.btn.print:hover{
    background: var(--bg-color);
}

.btn.edit{
    border: none;
    background: var(--yellow-color);
    color: var(--black);
    padding: 0.2rem 0.6rem;
    font-size: 0.7rem;
    font-weight: 500;
}

.btn.edit:hover{
    background: var(--bg-color);
    color: var(--accent-color);
}

.btn.delete{
    border: none;
    background: var(--red-color);
    color: var(--color-ligth);
    padding: 0.4rem 0.8rem;
    font-size: 0.7rem;
    font-weight: 500;
}

.btn.delete:hover{
    background: var(--bg-color);
    color: var(--accent-color);
}

.btn.save{
    border: none;
    background: var(--bg-green);
    color: var(--color-ligth);
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 500;
}  

.btn.save:hover{
    background: var(--bg-color);
    color: var(--accent-color)
}

.message__div{
    background-color: var(--color-ligth);
    border-radius: 0.2rem;
    color: var(--red-color);
    font-weight: 600;
}

.red__star{
    color: var(--red-color);
    font-size: 1rem;
}




/*=========RESPONSIVE FOR TABLETS=============*/
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }
    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 4.6rem;
    }
}


/*=========RESPONSIVE FOR PHONES=============*/
@media screen and (max-width: 600px) {
    h1{
        font-size: 2.2rem;
        line-height: 1.3;
    }
}
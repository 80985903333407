.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .modal-dispose {
    display: none;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal__top{
    display: flex;
    align-items: center;
    background-color: #202020;
    gap: 5rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    justify-content: space-between;
  }
  
  .btn__close{
    background-color: #f00;
    color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
  }
  
  .btn__close:hover{
    transition: all 500ms ease;
    cursor: pointer;
    background-color: #202020;
  }
  
  .modal__body{
    display: flex;
    color: black;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .modal__body__items{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
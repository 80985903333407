.anamnezaForm__container{
  color: black;

}

.anamneza__header{
  background-color: #d4d4d4;
  display: flex;
  padding: 1rem 0.6rem;
  border-radius: 0.3rem;
}

.anamneza__form__body{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  background-color: #d4d4d4;
  padding: 1rem 0.6rem;
  border-radius: 0.3rem;
}

.anamnezaFormFooter{
  display: flex;
  gap: 1rem;
}

.radioButton__grid__twoCollumns{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem; 
}

.chk__button{
  width: 20px;
}
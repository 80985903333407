.odontogram__top{
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.odontogram__upper__left{
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.odontogram__body{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1.2fr 1.4fr;
  gap: 1rem;
}

.odontogram__tooth__grid{
  padding: 0.7rem;
  gap: 0.2rem;
  background-color: #d4d4d4;
  border-radius: 0.5rem;
  color: black;
  display: flex;
  place-items: center; 
}


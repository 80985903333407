.notfound__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 23.7rem;
    padding-top: 8rem;
}
.notfound__container h1{
    color: var(--accent-color);
}
.notfound__container .btn{
    margin-top: 2rem;
    display: inline-block;
}
.rtg__container{
  margin-top: 2rem;
}


.rtg__table__body{
  background-color: var(--color-ligth);
  color: black;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rtg__table__body__top{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-weight: 700;
  font-size: 13px;
  padding: 0.5rem;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  align-items: center;
}

.rtg__table__body__top.data{
  font-size: 12px;
  font-weight: 500;
}

.rtg__table__top{
  background-color: #d4d4d4;
  color: black;
  padding: 0.4rem 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}

.rtg__table__top__left{
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.rtg__image__div{
  width: 50%;
}
.rtg__image__div:hover{
  cursor: pointer;
}
.rtg__image__div > img {
  border-radius: 0.3rem;
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.1s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 7px;
    border-radius: 1rem;
    background-color: var(--accent-color);
    cursor: pointer;
}

.medicalData__container{
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 2rem;
  margin-top: 2rem;
}

.medicalData__container__left{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.medicalRecordsTable{
  background-color: #d4d4d4;
  color: black;
  border-radius: 0.3rem;
}

.medicalRecordsTableBody{
  background-color: #fff;
  color: black;
  padding: 0.6rem;
}

.medicalRecordsTableBody > p{
  font-size: 14px;
  font-weight: 600;
}

.medicalRecordsTableBody > p > span{
  font-size: 14px;
  font-weight: 400;
  color: red;
}


.medicalRecordsTableTop{
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  padding: 0.3rem 0.6rem;
}

.medicalRecordsTableTop__left{
  display: flex;
  align-items: center;
}

.medicalRecordsTableBodyTop{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 700;
  font-size: 13px;
  padding: 0.5rem;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  align-items: center;
}

.medicalRecordsTableBodyTop.data{
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 11px;
}

.medicalRecordsTableBodyTop__agreement{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}


.s0 {
    fill: #ffffff;
  }

  .s1 {
    fill: transparent;
  }

  .s1-red {
    fill: #f00;
  }

  .s1-blue {
    fill: blue;
  }

  .s2 {
    fill: #fff;
  }

  .s2-red{
    fill: #f00;
  }

  .s3 {
    fill: #000;
  }

  .s4 {
    fill: #000002;
  }

  .s5 {
    stroke: #000000;
    stroke-miterlimit: 3;
  }

  .s5.blue{
    stroke: blue;
  }

  .g1-none{
    display: none;
  }
.patientInfo__container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 2rem;
}

.patientInfo__left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.infoCard__left>p {
  font-size: 14px;
  font-weight: 600;
}

.infoCard__left>p>span {
  font-weight: 400;
}


.patientInfo__right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.patientInfoCard__top {
  display: flex;
  justify-content: space-between;
  background-color: #d4d4d4;
  color: black;
  padding: 0.6rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.infoCard__container {
  background-color: #fff;
  padding: 1rem;
  color: black;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;
}

.infoCard__left {
  display: flex;
  flex-direction: column;
}

.infoCard_right>img {
  width: 90%;
}

.doned__treatments__top {
  display: flex;
  justify-content: space-between;
  background-color: #d4d4d4;
  color: black;
  padding: 0.5rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.table__first__row__patient__info {
  display: grid;
  color: black;
  grid-template-columns: 2fr 3fr 1.1fr 1.3fr 1fr 1fr 1fr;
  background-color: white;
  padding: 0.2rem 0.2rem;
}

.table__first__row__patient__info__treatments {
  display: grid;
  color: black;
  grid-template-columns: 2fr repeat(4, 1fr);
  background-color: white;
  padding: 0.2rem 0.2rem;
}

.table__first__row__patient__actions {
  display: flex;
  justify-content: space-between;
  background-color: white;
  gap: 1rem;
  padding: 1rem;
}

.table__first__row__patient__info,
.table__first__row__patient__info__treatments>p {
  font-size: 12px;
}

.table__data {
  padding: 1rem;
  background-color: white;
  color: black;
}

.table__future__exams__top {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 700;
  font-size: 13px;
  padding: 0.5rem;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  align-items: center;
}

.table__future__exams__top.data {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 11px;
}

.table__future__exams__top.no__data {
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 13px;
  grid-template-columns: 1fr;
  place-items: center;
}

.table__interventions__top {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
  font-size: 14px;
  font-weight: 700;
  background-color: #d4d4d4;
  padding: 0.5rem;
  border-radius: 0.3rem;
  align-items: center;
}

.table__interventions__top.data {
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 500;
}

.table__notes__top {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 1fr 1fr;
  font-size: 14px;
  font-weight: 700;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  padding: 0.5rem;
  align-items: center;
}

.table__notes__top.data {
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 400;
}

.table__donedTreatments__top {
  font-size: 12px;
  font-weight: 600;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  padding: 0.5rem;
  align-items: center;
  display: grid;
  gap: 0.7rem;
  grid-template-columns: 1.4fr 1.3fr 1fr 1.5fr 1fr 1.5fr 1fr 1fr;
}

.table__donedTreatments__top.data {
  margin-top: 0.5rem;
  font-size: 11x;
  font-weight: 400;
}

.table__donedTreatments__top.data:hover {
  cursor: pointer;
}

.page__selector__div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
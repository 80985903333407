.odontogram__tables__container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.table__tooth__history__top{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 0.5fr;
  font-weight: 700;
  font-size: 13px;
  padding: 0.5rem;
  background-color: #d4d4d4;
  border-radius: 0.3rem;
  align-items: center;
}


.table__planed__interventions__top{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
  align-items: center;
}

.table__planed__interventions__top.data{
  font-size: 14px;
  font-weight: 500;
}

.table__tooth__history__top.data:hover{
  cursor: pointer;
}


.table__rtg__top{
  display: grid;
  grid-template-columns: 1.1fr 1.3fr 1.4fr 1fr 1fr;
  font-size: 14px;
  align-items: center;
}

.table__rtg__top.data{
  font-size: 12px;
  font-weight: 500;
}
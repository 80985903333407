.login__container{
    display: grid;
    place-items: center;
    justify-content: center;
}

.login__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1.2rem;
    font-weight: 300;
    background-color: var(--bg-green);
    padding: 2rem 3rem;
    border-radius: 2rem;
}

.login__h2{
    color: white;
}

.login__info h3, .login__info h2{
    font-weight: 300;
}

.log__form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.log__div{
    display: flex;
    justify-content: center;
}

.btn.login__btn{
    border: 2px solid white;
}

.btn.login__btn:hover{
    background-color: white;
    color: var(--bg-green);
}


@media screen and (max-width: 1024px) {
    .login__container{
        grid-template-columns: 1fr 1fr;
    }
    
    .login__info h2{
        font-size: 130%;
    }

}


@media screen and (max-width: 600px) {
    .login__container{
        grid-template-columns: 1fr;
    }

    .login__info{
        width: 100%;
    }

    .login__info h3 span, .login__info h2 span{
        margin-left: 0;
    }
}

.tooth_selector_main_div {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 0.8rem;
}

.single_tooth_selector {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.2rem;
    border: 1px solid #fff;
}

.single_tooth_selector:hover {
    cursor: pointer;
}

.single_tooth_selector.blue {
    border-color: #202020;
    background-color: lightblue;
}
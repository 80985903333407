.header__content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-green);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}

.header__content__left{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__content__right{
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.home__section{
    margin-top: 5rem;
}


.main__header{
    height: 50vh;
    background: #d4d4d4;
    display: grid;
    place-items: center;
    margin-top: 0;
    padding: 4rem 0;
}

.main__header-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}


.main__header-left h4{
    color: var(--accent-color);
    font-weight: 400;
}


.main__header-right{
    display: grid;
    place-items: center;
}

.hammer-pic{
    width: 60%;
}

.toothChartDiv{
    margin-top: 1rem;
    display: flex;
    background-color: #d4d4d4;
    height: 610px;
    width: 600px;
}


/*=========FOOTER==========*/

footer{
    margin-top: 7rem;
}


.footer__copyright{
    background: var(--bg-green-dark);
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--color-ligth);
    text-align: center;
    padding: 2rem 0rem 2rem 0rem;
}


/*=========RESPONSIVE FOR TABLETS=============*/
@media screen and (max-width: 1024px) {
    /*MAIN HEADER*/
    .main__header{
        height: fit-content;
        padding: 5rem 0 1rem;
    }
    .main__header-container{
        gap: 0;
    }
    .hammer-pic{
        width: 100%;
        margin-bottom: 22rem;
    }
}


/*=========RESPONSIVE FOR PHONES=============*/
@media screen and (max-width: 600px) {
    /*MAIN HEADER*/
    .main__header-container{
        grid-template-columns: 1fr;
    }
    .main__header{
        margin-top: 0;
        padding: 12rem 0 0;
    }
    .main__header-left h2{
        font-size: 1.5rem;
    }
    .main__header-right{
        display: flex;
        justify-content: right;
    }
    .main__header-img{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .hammer-pic{
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 15rem;
    }
}
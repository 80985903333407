.password_reset__container {
    display: grid;
    place-items: center;
    justify-content: center;
}

.password_reset__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1.2rem;
    font-weight: 300;
    background-color: var(--bg-green);
    padding: 2rem 3rem;
    border-radius: 2rem;
}

.password_reset__h2 {
    color: white;
}

.password_reset__info h3,
.password_reset__info h2 {
    font-weight: 300;
}

.log__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.log__div {
    display: flex;
    justify-content: center;
}

.btn.password_reset__btn {
    border: 2px solid white;
}

.btn.password_reset__btn:hover {
    background-color: white;
    color: var(--bg-green);
}


@media screen and (max-width: 1024px) {
    .password_reset__container {
        grid-template-columns: 1fr 1fr;
    }

    .password_reset__info h2 {
        font-size: 130%;
    }

}


@media screen and (max-width: 600px) {
    .password_reset__container {
        grid-template-columns: 1fr;
    }

    .password_reset__info {
        width: 100%;
    }

    .password_reset__info h3 span,
    .password_reset__info h2 span {
        margin-left: 0;
    }
}
.patientAddEdit__top {
  background-color: #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  color: black;
}

.patientAddEdit__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  color: black;
}

.date__input {
  width: 97%;
}
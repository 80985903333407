nav {
    height: 5rem;
    width: 100vw;
    background: var(--bg-green);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    filter: drop-shadow(0.1rem 0 0.4rem var(--accent-color));
}

.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.nav__p {
    margin-top: 1px;
    margin-right: auto;
}

.nav__p__link {
    margin-top: 1px;
    margin-right: auto;
}

.nav__p__link:hover {
    cursor: pointer;
}

.logo {
    width: 3rem;
    display: block;
}

.nav__links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}

.nav__links a {
    transition: var(--transition);
}

.nav__links a:hover {
    filter: drop-shadow(0 0 0.8rem var(--accent-color));
    color: var(--accent-color);
}

.active-nav {
    position: relative;
}

.active-nav::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.05rem;
    left: 0;
    right: 0;
    background: var(--accent-color);
    margin-top: 0.05rem;
    position: absolute;

}

.lang__select {
    border: 2px solid white;
    padding: 0.2rem;
    border-radius: 0.4rem;
    background-color: var(--bg-green);
    color: var(--color-ligth);
}

.logout_navlink {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    transition: var(--transition);
}

.logout_navlink:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 0.8rem var(--accent-color));
}

@media screen and (max-width: 1024px) {
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__toggle-btn svg {
        color: var(--accent-color);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }

    .active-nav {
        display: none;
    }

    .active-nav::after {
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;

    }

    .nav__links li a {
        background-color: var(--bg-green);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }

    .nav__links a:hover {
        filter: drop-shadow(0 0 0 var(--accent-color));
        cursor: pointer;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }

    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }

    .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }

    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }

    .nav__links li:nth-child(6) {
        animation-delay: 1s;
    }

    .nav__links li:nth-child(7) {
        animation-delay: 1.2s;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }


}
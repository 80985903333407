.plannedInterventionAdd__top{
  background: #d4d4d4;
  padding: 0.6rem;
  border-radius: 0.3rem;
}

.plannedInterventionForm{
  margin-top: 1rem;
  background-color: #d4d4d4;
  color: black;
  padding: 0.6rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.newReservation__top{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem;
  border-radius: 0.2rem;
  background-color: #d4d4d4;
  color: black;
}

.newReservation__form{
  background-color: #d4d4d4;
  color: black;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.newReservationFormFooter{
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.radioButton__flex__big{
  display: flex;
  align-items: center;
  gap: 2rem;
}